<template>
  <div class="bg-surface-default-low px-40 pb-40 pt-24">
    <div class="mx-auto flex max-w-[1280px] flex-col gap-16" role="menu">
      <aside class="flex gap-8">
        <h2 class="text-static-default-mid body-2 flex-1">
          {{ i18n(translations.categories) }}
        </h2>
        <RevLink
          class="body-2-link"
          rel="no-follow"
          :to="universeURL"
          :tracking="{
            zone: 'mega menu',
            name: universeTitle,
            value: 'view_all',
          }"
          >{{ i18n(translations.see_all) }}</RevLink
        >
      </aside>
      <ul
        v-if="items.length"
        :aria-label="universeTitle"
        class="grid grid-cols-5 gap-32"
        role="menu"
      >
        <li v-for="item in items" :key="item.id" class="max-w-256" role="none">
          <RevLink
            class="body-2 flex flex-col gap-8"
            role="menuitem"
            :to="item.url"
            :tracking="{
              zone: 'mega menu',
              name: universeTitle,
              value: item.title,
            }"
            :underlined="false"
          >
            <RevIllustration
              v-if="item.image"
              :alt="item.title"
              class="rounded-lg"
              :height="960"
              :src="item.image"
              :width="1280"
            />
            <span class="text-action-default-low body-1-bold">
              {{ item.title }}
            </span>
          </RevLink>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'

import translations from './NavMenu.translations'

const i18n = useI18n()

defineProps<{
  universeTitle: string
  universeURL: string
  items: Array<{
    id: number
    title: string
    image?: string
    url: string
  }>
}>()
</script>
