<template>
  <div
    class="flex flex-wrap items-center gap-16 px-20 py-16 lg:gap-56 lg:px-32 lg:py-20"
  >
    <BurgerMenuIcon v-if="!newNavbar" class="block flex-1 grow lg:hidden" />
    <BurgerMegaMenu v-else class="block flex-1 grow lg:hidden" />
    <TheLogo />
    <NavLinks class="hidden lg:block" />
    <SearchBar class="order-last w-full grow lg:order-none lg:flex-1" />
    <div class="flex items-center justify-end gap-8">
      <UserIcon />
      <CartIconAndBubble data-test="icon-cart" />
    </div>
    <MenuFullscreen
      v-if="!newNavbar"
      v-show="burgerMenuState === 'open'"
      class="z-20 lg:hidden"
    />
  </div>
</template>

<script setup lang="ts">
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'

import SearchBar from '~/scopes/search/SearchBar/SearchBar.vue'

import { useNavigation } from '../composables/useNavigation'

import BurgerMegaMenu from './BurgerMegaMenu.vue'
import BurgerMenuIcon from './BurgerMenuIcon.vue'
import CartIconAndBubble from './CartIconAndBubble.vue'
import MenuFullscreen from './MenuFullscreen.vue'
import NavLinks from './NavLinks.vue'
import TheLogo from './TheLogo.vue'
import UserIcon from './UserIcon.vue'

const { state: burgerMenuState } = useNavigation()
const experiments = useExperiments()
const newNavbar = experiments['experiment.newNavigation'] === 'new'
</script>
