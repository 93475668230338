const tradeInTitleSmartphones = {
  id: 'menu_trade-in_title_smartphones',
  defaultMessage: 'Trade-in',
}
const mobilePlanTitleSmartphones = {
  id: 'menu_mobile-plan_title_smartphones',
  defaultMessage: 'Exclusive deals',
}
const tradeInDescriptionSmartphones = {
  id: 'menu_trade-in_description_smartphones',
  defaultMessage: 'Get up to $900 for your old device',
}
const mobilePlanDescriptionSmartphones = {
  id: 'menu_mobile-plan_description_smartphones',
  defaultMessage: 'Select a Visible+ plan at checkout and save',
}
const tradeInTitleGamingConsoles = {
  id: 'menu_trade-in_title_gaming-consoles',
  defaultMessage: 'Trade-in',
}
const tradeInDescriptionGamingConsoles = {
  id: 'menu_trade-in_description_gaming-consoles',
  defaultMessage: 'Get up to $900 for your old device',
}
const tradeInTitleTablets = {
  id: 'menu_trade-in_title_tablets',
  defaultMessage: 'Trade-in',
}
const tradeInDescriptionTablets = {
  id: 'menu_trade-in_description_tablets',
  defaultMessage: 'Get up to $900 for your old device',
}
const tradeInTitleLaptops = {
  id: 'menu_trade-in_title_laptops',
  defaultMessage: 'Trade-in',
}
const tradeInDescriptionLaptops = {
  id: 'menu_trade-in_description_laptops',
  defaultMessage: 'Get up to $900 for your old device',
}
const tradeInTitleSmartwatches = {
  id: 'menu_trade-in_title_smartwatches',
  defaultMessage: 'Trade-in',
}
const tradeIndDescriptionSmartwatches = {
  id: 'menu_trade-in_description_smartwatches',
  defaultMessage: 'Get up to $900 for your old device',
}
const tradeInTitleAudio = {
  id: 'menu_trade-in_title_audio',
  defaultMessage: 'Trade-in',
}
const tradeInDescriptionAudio = {
  id: 'menu_trade-in_description_audio',
  defaultMessage: 'Get up to $900 for your old device',
}
export default {
  goodToKnow: {
    id: 'good_to_know',
    defaultMessage: 'Good to know',
  },

  // PROD US SMARTPHONES
  // https://www.backmarket.com/en-us/l/smartphones/0744fd27-8605-465d-8691-3b6dffda5969
  'tradeInTitle_0744fd27-8605-465d-8691-3b6dffda5969': tradeInTitleSmartphones,
  'tradeInDescription_0744fd27-8605-465d-8691-3b6dffda5969':
    tradeInDescriptionSmartphones,
  'mobilePlanTitle_0744fd27-8605-465d-8691-3b6dffda5969':
    mobilePlanTitleSmartphones,
  'mobilePlanDescription_0744fd27-8605-465d-8691-3b6dffda5969':
    mobilePlanDescriptionSmartphones,
  // PROD US GAMING CONSOLES
  // https://www.backmarket.com/en-us/l/consoles/afa370e4-ee3a-426a-a783-c94c899d664a
  'tradeInTitle_afa370e4-ee3a-426a-a783-c94c899d664a':
    tradeInTitleGamingConsoles,
  'tradeInDescription_afa370e4-ee3a-426a-a783-c94c899d664a':
    tradeInDescriptionGamingConsoles,
  // PROD US TABLETS
  // https://www.backmarket.com/en-us/l/tablets/076e3232-bda6-424b-81a4-9a6c9c08e8ee
  'tradeInTitle_076e3232-bda6-424b-81a4-9a6c9c08e8ee': tradeInTitleTablets,
  'tradeInDescription_076e3232-bda6-424b-81a4-9a6c9c08e8ee':
    tradeInDescriptionTablets,
  // PROD US LAPTOPS
  // https://www.backmarket.com/en-us/l/macbook-and-laptops/02661471-d1ce-4d43-88e3-4bc4b7d4c8d6
  'tradeInTitle_02661471-d1ce-4d43-88e3-4bc4b7d4c8d6': tradeInTitleLaptops,
  'tradeInDescription_02661471-d1ce-4d43-88e3-4bc4b7d4c8d6':
    tradeInDescriptionLaptops,
  // PROD US SMARTWATCHES
  // https://www.backmarket.com/en-us/l/watches/4ee50ebd-1eb4-4436-a797-80828ce28cc5
  'tradeInTitle_4ee50ebd-1eb4-4436-a797-80828ce28cc5': tradeInTitleSmartwatches,
  'tradeInDescription_4ee50ebd-1eb4-4436-a797-80828ce28cc5':
    tradeIndDescriptionSmartwatches,
  // PROD US AUDIO
  // https://www.backmarket.com/en-us/l/sound-music-device/91ffe3ce-47dd-4e42-9096-ccf96412f3f9
  'tradeInTitle_91ffe3ce-47dd-4e42-9096-ccf96412f3f9': tradeInTitleAudio,
  'tradeInDescription_91ffe3ce-47dd-4e42-9096-ccf96412f3f9':
    tradeInDescriptionAudio,

  // PROD EU SMARTPHONES
  // https://www.backmarket.fr/fr-fr/l/smartphones/6c290010-c0c2-47a4-b68a-ac2ec2b64dca
  'tradeInTitle_6c290010-c0c2-47a4-b68a-ac2ec2b64dca': tradeInTitleSmartphones,
  'tradeInDescription_6c290010-c0c2-47a4-b68a-ac2ec2b64dca':
    tradeInDescriptionSmartphones,
  'mobilePlanTitle_6c290010-c0c2-47a4-b68a-ac2ec2b64dca':
    mobilePlanTitleSmartphones,
  'mobilePlanDescription_6c290010-c0c2-47a4-b68a-ac2ec2b64dca':
    mobilePlanDescriptionSmartphones,
  // PROD EU GAMING CONSOLES
  // https://www.backmarket.fr/fr-fr/l/consoles-et-jeux-video/0a092eda-8a90-4b60-85bd-8a3123b2fd84
  'tradeInTitle_0a092eda-8a90-4b60-85bd-8a3123b2fd84':
    tradeInTitleGamingConsoles,
  'tradeInDescription_0a092eda-8a90-4b60-85bd-8a3123b2fd84':
    tradeInDescriptionGamingConsoles,
  // PROD EU TABLETS
  // https://www.backmarket.fr/fr-fr/l/tablettes-reconditionnees/5a3cfa21-b588-49b1-b4e9-2636bec68ada
  'tradeInTitle_5a3cfa21-b588-49b1-b4e9-2636bec68ada': tradeInTitleTablets,
  'tradeInDescription_5a3cfa21-b588-49b1-b4e9-2636bec68ada':
    tradeInDescriptionTablets,
  // PROD EU LAPTOPS
  // https://www.backmarket.fr/fr-fr/l/ordinateur-portable-reconditionne/630dab14-5051-49b9-bc7b-bb20876d4850
  'tradeInTitle_630dab14-5051-49b9-bc7b-bb20876d4850': tradeInTitleLaptops,
  'tradeInDescription_630dab14-5051-49b9-bc7b-bb20876d4850':
    tradeInDescriptionLaptops,
  // PROD EU SMARTWATCHES
  // https://www.backmarket.fr/fr-fr/l/montre-connectee-reconditionnee/0894adca-7735-40d3-a34b-5a77358e3937
  'tradeInTitle_0894adca-7735-40d3-a34b-5a77358e3937': tradeInTitleSmartwatches,
  'tradeInDescription_0894adca-7735-40d3-a34b-5a77358e3937':
    tradeIndDescriptionSmartwatches,
  // PROD EU AUDIO
  // https://www.backmarket.fr/fr-fr/l/son-casque-enceinte-reconditionne/6a6b4d5a-c50d-4a61-9a7a-3c3b1ce00e50
  'tradeInTitle_6a6b4d5a-c50d-4a61-9a7a-3c3b1ce00e50': tradeInTitleAudio,
  'tradeInDescription_6a6b4d5a-c50d-4a61-9a7a-3c3b1ce00e50':
    tradeInDescriptionAudio,

  // PROD AP SMARTPHONES
  // https://www.backmarket.com.au/en-au/l/smartphones/e85ce098-7f65-4e20-9672-c09bfc2c0ed2
  // PROD AP GAMING CONSOLES
  //
  // PROD AP TABLETS
  // https://www.backmarket.com.au/en-au/l/ipad/1692e8f6-4671-4e3a-a99b-8b18841e6f35
  // PROD AP LAPTOPS
  // https://www.backmarket.com.au/en-au/l/laptop/c28d5dcd-74e1-45f4-8ce7-79fc72230881#
  // PROD AP SMARTWATCHES
  // https://www.backmarket.com.au/en-au/l/apple-watch/ce7db2e5-e878-47d8-99f4-f81f60383ead
  // PROD AP AUDIO
  // https://www.backmarket.com.au/en-au/l/headphone-earphone/2b512439-5588-439b-977e-fc367b836b13
}
