<template>
  <section class="mt-8 flex flex-col gap-16 p-24">
    <RevLink
      v-for="link in links"
      :key="link.name"
      class="body-2-bold"
      :to="link.href"
      :tracking="{ zone: 'nav', name: link.name }"
      :underlined="false"
    >
      {{ link.label }}
    </RevLink>
  </section>
</template>

<script setup lang="ts">
import { RevLink } from '@ds/components/Link'

import { useNavLinks } from '../../composables/useNavLinks'

const { links } = useNavLinks()
</script>
