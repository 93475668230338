import { useRoute, useRouter, useState } from '#app'
import { useHead } from '#imports'
import { computed } from 'vue'
import { type Router } from 'vue-router'

import type { Link } from '@backmarket/http-api'
import {
  type MenuItem,
  getSidebar,
} from '@backmarket/http-api/src/api-specs-navigation-experience/navigation/sidebar'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { type Logger } from '@backmarket/nuxt-module-logger/types'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import { linkToUrl } from '../linkToUrl'

export interface Item {
  children: Item[] | never[]
  color?: string
  icon?: string
  id: number
  image?: string
  title: string
  url: string
  plpId?: string
}

function getPLPId(link: Link | undefined) {
  if (!link) {
    return undefined
  }
  if (link.type === 'external') {
    return link.href.split('/').pop()
  }

  return link.href?.split('/').pop() ?? link.params?.uuid
}

function mapMenuItemtoItem(
  item: MenuItem,
  router: Router,
  logger: Logger,
): Item {
  return {
    children: item.children.map((elt) =>
      mapMenuItemtoItem(elt, router, logger),
    ),
    color: item.style?.text_color,
    icon: undefined,
    id: item.id,
    image: item.image,
    title: item.title,
    url: linkToUrl(item.link, router, logger),
    plpId: getPLPId(item.link),
  }
}

export function useNavigation() {
  const items = useState<Item[]>('menu-items', () => [])
  const router = useRouter()
  const route = useRoute()
  const logger = useLogger()
  const { trackClick } = useTracking()

  const fetchMenuItems = async () => {
    if (items.value.length === 0) {
      const { data } = await useHttpFetch(getSidebar)

      if (data.value) {
        items.value = data.value.map((item) =>
          mapMenuItemtoItem(item, router, logger),
        )
      }
    }
  }

  const activeMenu = useState<number | undefined>(
    'menu-active',
    () => undefined,
  )
  const isMenuActive = computed(() => activeMenu.value !== undefined)
  const setActiveMenu = (id: number | undefined) => {
    activeMenu.value = id
  }
  const clearActiveMenu = () => {
    activeMenu.value = undefined
  }

  const isOpen = useState('burger-menu-isOpen', () => false)
  useHead({
    bodyAttrs: {
      class: {
        'overflow-hidden lg:overflow-auto': isOpen.value,
      },
    },
  })
  const state = computed(() => (isOpen.value ? 'open' : 'closed'))
  const toggle = () => {
    clearActiveMenu()

    isOpen.value = !isOpen.value
    if (isOpen.value) {
      trackClick({ zone: 'nav', name: 'all_product' })
    }
  }

  const isActivePage = (link: string | undefined) => {
    if (!link) return false
    try {
      const url = new URL(link)

      return route.fullPath === url.pathname
    } catch (err) {
      return route.fullPath === link
    }
  }

  return {
    clearActiveMenu,
    fetchMenuItems,
    setActiveMenu,
    toggle,
    isActivePage,
    activeMenu,
    isMenuActive,
    isOpen,
    items,
    menu: items,
    state,
  }
}
