<template>
  <nav
    aria-label="menu"
    class="bg-surface-default-low relative transition-colors"
    :class="[isMenuActive ? '' : 'border-static-default-low border-b']"
  >
    <ul
      class="flex flex-col justify-center lg:h-48 lg:flex-row lg:items-center lg:px-32"
      role="menubar"
    >
      <li class="lg:h-48">
        <NavMenuItem
          :color="GOOD_DEALS.color"
          :icon="GOOD_DEALS.icon"
          :title="GOOD_DEALS.title"
          :url="GOOD_DEALS.url"
        />
      </li>
      <li v-for="(item, index) in items" :key="item.id" class="lg:h-48">
        <NavMenuItem
          :id="item.id"
          :hasPopup="item.children.length > 0"
          :title="item.title"
          :url="item.url"
          @blur="hideMenu"
          @focusin="
            () => showMenu({ id: item.id, title: item.title, position: index })
          "
          @mouseenter="
            () => showMenu({ id: item.id, title: item.title, position: index })
          "
          @mouseleave="hideMenu"
        >
          <div
            v-if="item.children.length"
            v-show="activeMenu === item.id"
            aria-label="submenu"
            class="border-static-default-low absolute inset-x-0 top-0 mt-48 flex border-t"
          >
            <Services
              v-if="item.plpId"
              class="bg-surface-default-mid max-w-256"
              :plpId="item.plpId"
            />
            <NavMenuContent
              class="flex-1"
              :items="item.children"
              :universeTitle="item.title"
              :universeURL="item.url"
            />
          </div>
        </NavMenuItem>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { IconSparkles } from '@ds/icons/IconSparkles'

import { useMegaMenu } from '../../composables/useMegaMenu'
import { useNavigation } from '../../composables/useNavigation'
import Services from '../NavBurgerMenu/Services.vue'

import t from './NavMenu.translations'
import NavMenuContent from './NavMenuContent.vue'
import NavMenuItem from './NavMenuItem.vue'

const i18n = useI18n()
const locale = useI18nLocale()
const GOOD_DEALS = {
  title: i18n(t.goodDeals),
  children: [],
  color: '#9D3963',
  icon: IconSparkles,
  url: `/${locale}/e/good-deals`,
}

const { items, isMenuActive, activeMenu } = useNavigation()

const { showMenu, hideMenu } = useMegaMenu()
</script>
